/**
 * @note Keep in sync with the list Asset::EQUIPMENT_FIELDS in the backend
 */
const equipmentFields = [
    'refrigerator',
    'freezer',
    'microwaveOven',
    'stove',
    'oven',
    'fullyEquippedKitchen',
    'hotWater',
    'shower',
    'gps',
    'airCondition',
    'airConditionDriving',
    'backupCamera',
    'auxPort',
    'bluetoothAudio',
    'parkingSensors',
    'cdPlayer',
    'childSafetySeat',
    'tv',
    'toiletType',
    'heating',
    'floorHeating',
    'blackoutCurtain',
    'blinds',
    'mosquitoNet',
    'towbar',
    'towbarSocket',
    'towbarSocketAdapter',
    'solarPanel',
    'awning',
    'awningCarpet',
    'marquis',
    'pillowsAndBlanketsIncluded',

    // TODO Delete in P2 owner addon implementation when equipment addons section is done also remove getEquipmentConvertedToOwnerEquipmentAddons usage form AssetResources.php
    'coffeeMachine',
    'bedLinenIncluded',
    'barbequeGrill',
    'outdoorFurniture',
    'mover',
    'towingMirrors',
    'bicycleCarrier',
];

export const featureFlagEquipmentFields = [
    'refrigerator',
    'freezer',
    'microwaveOven',
    'stove',
    'oven',
    'fullyEquippedKitchen',
    'hotWater',
    'shower',
    'gps',
    'airCondition',
    'airConditionDriving',
    'backupCamera',
    'auxPort',
    'bluetoothAudio',
    'parkingSensors',
    'cdPlayer',
    'childSafetySeat',
    'tv',
    'toiletType',
    'heating',
    'floorHeating',
    'blackoutCurtain',
    'blinds',
    'mosquitoNet',
    'towbar',
    'towbarSocket',
    'towbarSocketAdapter',
    'solarPanel',
    'awning',
    'awningCarpet',
    'marquis',
    'pillowsAndBlanketsIncluded',
];

export default equipmentFields;
