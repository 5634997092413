export default class AssetDamageService {
    constructor({ $axios }) {
        this.$axios = $axios;
    }

    /**
     * Get damages for given asset
     * @param {integer} assetId
     */
    getForAsset(assetId) {
        const config = {
            method: 'get',
            url: `/assets/${assetId}/damages`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Creates a new damage for given asset
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component
     *
     * @param {integer} assetId
     * @param {string} location
     * @param {string} description
     * @param {string|null} courseOfEvents (optional)
     * @param {string|null} bookingId (optional)
     */
    createDamage(assetId, location, description, courseOfEvents, bookingId) {
        const data = {
            assetId,
            location,
            description,
        };

        if (courseOfEvents) {
            data.courseOfEvents = courseOfEvents;
        }

        if (bookingId) {
            data.bookingId = bookingId;
        }

        const config = {
            method: 'post',
            url: `/asset-damages`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Update a damage
     *
     * Note that an image cannot be passed in here, it's pushed to the backend using the
     * ImageUpload component

     * @param {integer} id ID of the damage
     * @param {object} data Data to be updated
     */
    updateDamage(id, data) {
        const config = {
            method: 'put',
            url: `/asset-damages/${id}`,
            data,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Deletes a damage
     *
     * @param {integer} id ID of the damage to remove
     */
    deleteDamage(id) {
        const config = {
            method: 'delete',
            url: `/asset-damages/${id}`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Set damage as resolved
     *
     * @param {integer} id ID of the damage
     */
    resolveDamage(id) {
        const config = {
            method: 'patch',
            url: `/asset-damages/${id}/set-resolved`,
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees left (counter-clockwise)
     *
     * @param {integer} damageId ID of the damage
     * @param {string} type Which image to rotate (imageFront / imageBack)
     */
    rotateImageLeft(damageId, type) {
        const config = {
            method: 'post',
            url: `/asset-damages/${damageId}/rotate-image-left`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }

    /**
     * Rotates an image 90 degrees right (clockwise)
     *
     * @param {integer} damageId ID of the damage
     * @param {string} type Which image to rotate (imageFront / imageBack)
     */
    rotateImageRight(damageId, type) {
        const config = {
            method: 'post',
            url: `/asset-damages/${damageId}/rotate-image-right`,
            data: { type },
        };

        return this.$axios(config).then(response => response.data.data);
    }
}
