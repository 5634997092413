// Color names, copied from _colors.scss
export default {
    white: '#fff',
    primary: '#0E181E',
    blue: '#7C9DF8',
    'bright-blue': '#B7CFFF',
    disabled: '#978E96',
    warning: '#EC5E63',
    'warning-light': '#F28D90',
    'warning-dark': '#E73238',
    'darkest-gray': '#666666',
    gray: '#DDD9DE',
};
