// @note: Cancellation values should always match the backend logic in getCancellationFeePercentage()
export const CANCELLATION_THRESHOLDS = {
    LONG_NOTICE_CUTOFF: 61, // Used for calculating exact cutoff dates
    LONG_NOTICE: 60, // > 60 days before checkin
    MEDIUM_NOTICE_CUTOFF: 31,
    MEDIUM_NOTICE: 30, // >= 30 days before checkin
    SHORT_NOTICE_CUTOFF: 8,
    SHORT_NOTICE: 7, // >= 7 days before checkin
    NO_REFUND_NOTICE: 0, // 0-6 days before checkin
};

export const REFUND_TYPES = {
    FULL: 'full',
    PARTIAL: 'partial',
    NONE: 'none',
};

export const CANCELLATION_POLICIES = {
    FLEX: 'flex',
    STRICT: 'strict',
    STANDARD: 'standard',
};

export const REFUND_PERCENTAGES = {
    [REFUND_TYPES.FULL]: 100,
    [REFUND_TYPES.PARTIAL]: 75,
    [REFUND_TYPES.NONE]: 0,
};

export const CANCELLATION_FEE_PERCENTAGES = {
    [REFUND_TYPES.FULL]: 100 - REFUND_PERCENTAGES[REFUND_TYPES.FULL],
    [REFUND_TYPES.PARTIAL]: 100 - REFUND_PERCENTAGES[REFUND_TYPES.PARTIAL],
    [REFUND_TYPES.NONE]: 100 - REFUND_PERCENTAGES[REFUND_TYPES.NONE],
};

export const cancellationRules = {
    [CANCELLATION_POLICIES.FLEX]: {
        fullRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.LONG_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.FULL],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.FULL],
        },
        partialRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.SHORT_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.PARTIAL],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.PARTIAL],
        },
        noRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.NO_REFUND_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.NONE],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.NONE],
        },
    },
    [CANCELLATION_POLICIES.STRICT]: {
        partialRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.MEDIUM_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.PARTIAL],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.PARTIAL],
        },
        noRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.NO_REFUND_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.NONE],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.NONE],
        },
    },
    [CANCELLATION_POLICIES.STANDARD]: {
        fullRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.LONG_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.FULL],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.FULL],
        },
        partialRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.MEDIUM_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.PARTIAL],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.PARTIAL],
        },
        noRefund: {
            thresholdDays: CANCELLATION_THRESHOLDS.NO_REFUND_NOTICE,
            refundPercentage: REFUND_PERCENTAGES[REFUND_TYPES.NONE],
            feePercentage: CANCELLATION_FEE_PERCENTAGES[REFUND_TYPES.NONE],
        },
    },
};

export const BOOKING_CANCELLATION_TYPE_DECLINE = 'decline';
export const BOOKING_CANCELLATION_TYPE_WITHDRAW = 'withdraw';
export const BOOKING_CANCELLATION_TYPE_CANCEL = 'cancel';

const ownerReasons = [
    'already_rented_out',
    'own_use',
    'damaged',
    'uncomfortable_with_renter',
    'waiting_for_better_offer',
    'selling_the_vehicle',
    'winter_storage',
    'other',
];

export const bookingCancellationReasons = {
    owner: {
        decline: ownerReasons,
        cancel: ownerReasons,
    },
    renter: {
        withdraw: [
            'have_a_better_offer',
            'plans_changed',
            'uncomfortable_with_owner',
            'vehicle_not_up_to_expectations',
            'owner_declined_in_messages_not_available',
            'owner_decline_in_messages_damaged',
            'no_response_from_owner',
            'other',
        ],
        cancel: [
            'have_a_better_offer',
            'plans_changed',
            'uncomfortable_with_owner',
            'vehicle_not_up_to_expectations',
            'owner_decline_in_messages_damaged',
            'sickness',
            'bought_my_own',
            'other',
        ],
    },
};

export const reasonsThatTriggerDatepicker = ['already_rented_out', 'own_use', 'damaged', 'winter_storage', 'other'];

export const bookingCancellationTypes = [
    BOOKING_CANCELLATION_TYPE_DECLINE,
    BOOKING_CANCELLATION_TYPE_WITHDRAW,
    BOOKING_CANCELLATION_TYPE_CANCEL,
];
