import posthog from 'posthog-js';
import { actionTypes as featureFlagActionTypes } from '~/store/featureFlags';

export default function ({ $config, store }, inject) {
    posthog.init($config.posthogApiKey, {
        api_host: $config.posthogHostUrl,
        autocapture: true,
        persistence: 'memory',
        cross_subdomain_cookie: true,
        loaded(ph) {
            ph.onFeatureFlags((_, flagsWithVariants) => {
                store.dispatch(featureFlagActionTypes.SET_FLAGS, flagsWithVariants);
            });
        },
    });

    inject('posthog', posthog);
}
