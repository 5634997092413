/**
 * Mixin for handling modals in components
 */
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';
import { startOfYear, subYears } from 'date-fns';
import { actionTypes as bookingActions } from '~/store/booking';
import { actionTypes as cartActions } from '@/store/cart';
import { actionTypes as modalActions } from '@/store/modal';
import { actionTypes as userActions } from '@/store/user';
import { ACTIVE, COMPLETED, CONFIRMED, PENDING_CONFIRMATION } from '@/config/bookingStatuses';
import { actionTypes as filterActionTypes } from '~/store/filters';
import namedColors from '~/config/namedColors';
import { ADDONS_ANNOUNCED_AT } from '~/config/customTimestampsKeys';

export default {
    methods: {
        async defaultLoginCallback() {
            const { isRenter, isOwner, hasActivatedAnyVehicleAtSomePoint, hasAnyActiveVehicle, favoredAsset } =
                this.$store.getters;

            if (favoredAsset) {
                this.$meService.addAssetToFavorites(favoredAsset);
                this.$store.dispatch(userActions.CLEAR_FAVORED_ASSET);

                return;
            }

            if (this.$cookies.get('auth.redirect')) {
                setTimeout(() => this.$router.push(this.$cookies.get('auth.redirect')));

                return;
            }

            if (isRenter) {
                const bookings = await this.$bookingService.getBookings();

                if (bookings.length === 0) {
                    return;
                }

                const pendingBookingRequests = bookings.filter(b => b.ecommerceStatus === PENDING_CONFIRMATION);
                const confirmedBookings = bookings.filter(b => [CONFIRMED, ACTIVE].includes(b.ecommerceStatus));
                const sortedCompletedBookings = bookings
                    .filter(b => b.ecommerceStatus === COMPLETED)
                    .sort((a, b) => b.completed_at - a.completed_at);

                if (confirmedBookings.length === 1) {
                    await this.$router.push(
                        this.localePath({ name: 'booking', params: { id: confirmedBookings[0].id } })
                    );
                } else if (confirmedBookings.length > 0 || pendingBookingRequests.length > 0) {
                    await this.$router.push(this.localePath({ name: 'bookings' }));
                } else if (sortedCompletedBookings.length > 0) {
                    await this.$router.push(
                        this.localePath({ name: 'booking', params: { id: sortedCompletedBookings[0].id } })
                    );
                }

                return;
            }

            if (isOwner) {
                if (!hasActivatedAnyVehicleAtSomePoint) {
                    await this.$router.push(this.localePath({ name: 'getting-started' }));
                } else if (this.featureEnabled('renterAccess') && hasAnyActiveVehicle) {
                    setTimeout(() => this.$router.push(this.localePath({ name: 'owner-dashboard' })));
                } else {
                    await this.$router.push(this.localePath({ name: 'edit-vehicles' }));
                }
            }
        },
        displayLoginModal(loggedInCallback, data) {
            /**
             * Redirects user to:
             *
             * - The value of '?redirect=' if specified as query parameter
             * - No redirecting if user saved a vehicle while logged out
             * - Getting started if it's the first time logging in
             * - Getting started if renter has not completed their profile
             * - Getting started if user is owner and has not yet published their first vehicle
             * - Bookings if user is owner and has their vehicle published
             *
             * I.e., renters are not redirected if they're finished with their profile.
             */

            const callbackOnSuccess = loggedInCallback || this.defaultLoginCallback;

            this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'LoginModal',
                    style: { lesserPadding: true },
                    data,
                })
                .then(async () => {
                    await callbackOnSuccess();

                    this.displayObtrusiveModalsInOrder();
                })
                .catch(() => {});
        },
        displaySignUpModal(data) {
            this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'SignUpModal',
                    data,
                    style: { lesserPadding: true },
                })
                .then(() => {})
                .catch(() => {});
        },
        displayContinueLoginModal(data) {
            this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ContinueLoginModal',
                    data,
                    style: { lesserPadding: true },
                })
                .then(() => {})
                .catch(() => {});
        },
        displayInstallmentPaymentModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'InstallmentPaymentModal',
                    data,
                })
                .catch(() => {});
        },
        displayBookingCancelModal(data, style, closeable = true) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'BookingCancelModal',
                closeable,
                style,
                data,
            });
        },
        displayBookingChangeRequestModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'BookingChangeRequestModal',
                    data,
                })
                .catch(() => {});
        },
        displayBookingDeductibleBuydownModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'BookingDeductibleBuydownModal',
                    data,
                })
                .catch(() => {});
        },
        displayAddInsuranceAddonsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'AddInsuranceAddonsModal',
                    data,
                    style: { lesserPadding: true },
                })
                .catch(() => {});
        },
        displayAdditionalFiltersModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'AdditionalFiltersModal',
                    style: { noPadding: true },
                    data,
                })
                .catch(() => {
                    this.$store.dispatch(filterActionTypes.CLEAR_ADDITIONAL_STAND_BY_FILTERS);
                });
        },
        displayAdditionalDriverModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'AdditionalDriverModal',
                    // We have a custom close button for this modal
                    closeable: false,
                    data,
                })
                .catch(() => {});
        },
        displaySendMessageModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'SendMessageModal',
                    data,
                })
                .catch(() => {
                    // User has cancelled, clear the cart (in case we created pending message)
                    this.$store.dispatch(cartActions.CLEAR);
                });
        },
        displayForgotPasswordModal(data) {
            this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ForgotPasswordModal',
                    style: { lesserPadding: true },
                    data,
                })
                .then(() => {})
                .catch(() => {});
        },
        displaySupportRequestModal() {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'SupportRequestModal',
                closeable: true,
            });
        },
        displayOverlappingDatesModal() {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'OverlappingDatesModal',
                closeable: true,
            });
        },
        displayUnavailableDatesModal(period, style) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'UnavailableDatesModal',
                closeable: true,
                data: { period },
                style,
            });
        },
        displayUnavailableDatesInfoModal(period, style) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'UnavailableDatesInfoModal',
                closeable: true,
                data: { period },
                style,
            });
        },
        displayInsuranceLimitationModal(data, closeable = false) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'InsuranceLimitationModal',
                style: { lesserPadding: true },
                closeable,
                data,
            });
        },
        displayBookingAlertModal(data, closeable = false) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'BookingAlertModal',
                style: { lesserPadding: true },
                closeable,
                data,
            });
        },
        displaySelectNameModal(closeable = false) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'SelectNameModal',
                closeable,
            });
        },
        displayAcceptUserTermsModal(data) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'AcceptUserTermsModal',
                closeable: false,
                obtrusive: true,
                data,
            });
        },
        displayOptForOmocomInteriorInsuranceModal(data) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'OptForOmocomInteriorInsuranceModal',
                closeable: false,
                obtrusive: true,
                data,
                style: { lesserPadding: true },
            });
        },
        displayCompleteBookingPaymentModal(style) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'CompleteBookingPaymentModal',
                closeable: false,
                style,
            });
        },
        displayStripeAccountVerificationModal() {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'StripeAccountVerificationModal',
                    closeable: true,
                })
                .then(() => {})
                .catch(() => {});
        },
        displayConfirmOmocomDetailsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ConfirmOmocomDetailsModal',
                    closeable: false,
                    obtrusive: true,
                    data,
                })
                .then(() => {});
        },
        displayRenterProfileCompletedModal() {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'RenterProfileCompletedModal',
                })
                .catch(() => {});
        },
        displayVehicleCharacteristicsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleCharacteristicsModal',
                    data,
                })
                .catch(() => {});
        },
        displayVehicleEquipmentModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleEquipmentModal',
                    data,
                })
                .catch(() => {});
        },
        displayVehicleAddonsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleAddonsModal',
                    data,
                })
                .catch(() => {});
        },
        displayManageVehicleAddonsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ManageVehicleAddonsModal',
                    data,
                    style: { lesserPadding: true },
                })
                .then(() => {
                    return { status: 'ok' };
                })
                .catch(() => {});
        },
        displayVehicleRulesModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleRulesModal',
                    data,
                })
                .catch(() => {});
        },
        displayVehicleReviewsModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleReviewsModal',
                    data,
                })
                .catch(() => {});
        },
        displayCancellationInfoModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'CancellationInfoModal',
                    data,
                })
                .catch(() => {});
        },
        displayAdditionalEquipmentModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'AdditionalEquipmentModal',
                    data,
                })
                .catch(() => {});
        },
        displayVehicleDescriptionModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VehicleDescriptionModal',
                    data,
                })
                .catch(() => {});
        },
        displayVerifyVehicleModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'VerifyVehicleModal',
                    data,
                })
                .catch(() => {});
        },
        displayPriceCalculatorModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'PriceCalculatorModal',
                    mode: 'fullscreen',
                    sections: true,
                    data,
                })
                .catch(() => {});
        },
        displayExtendDepositReleaseDeadlineModal() {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ExtendDepositReleaseDeadlineModal',
                })
                .catch(() => {});
        },
        displayCompleteDamageModal(damage) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'CompleteDamageModal',
                    data: { damage },
                })
                .then(async () => {
                    try {
                        await this.$store.dispatch(bookingActions.FETCH_BOOKING, this.$store.getters.booking.id);
                    } catch (error) {
                        this.$sentry.captureException(error);
                    }
                })
                .catch(() => {});
        },
        displayExtraChargeModal(extraCharge = null, fixedCategory, showOnlyEquipmentCategories = false) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ExtraChargeModal',
                    data: {
                        extraCharge,
                        fixedCategory,
                        showOnlyEquipmentCategories,
                    },
                })
                .catch(() => {})
                .finally(async () => {
                    // Reload booking to update its list of extra charges
                    try {
                        await this.$store.dispatch(bookingActions.FETCH_BOOKING, this.$store.getters.booking.id);
                    } catch (error) {
                        this.$sentry.captureException(error);
                    }
                });
        },
        displayExtraChargeDiscountModal(booking, extraChargeDiscount = null) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'ExtraChargeDiscountModal',
                    data: {
                        booking,
                        extraChargeDiscount,
                    },
                })
                .catch(() => {})
                .finally(async () => {
                    // Reload booking to update its list of extra charges
                    try {
                        await this.$store.dispatch(bookingActions.FETCH_BOOKING, booking.id);
                    } catch (error) {
                        this.$sentry.captureException(error);
                    }
                });
        },
        displayCamptooWelcomeModal() {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'CamptooWelcomeModal',
                    closeable: false,
                    header: 'logo',
                })
                .catch(() => {});
        },
        displayBookingReviewModal() {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'BookingReviewModal',
                })
                .catch(() => {});
        },
        displayBookingMenuSectionModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'BookingMenuSectionModal',
                    data,
                    header: 'return',
                })
                .catch(() => {});
        },
        displayDepositPaymentModal(data) {
            return this.$store
                .dispatch(modalActions.MODAL_OPEN, {
                    component: 'DepositPaymentModal',
                    data,
                })
                .catch(() => {});
        },
        displayCamperMaintenanceModal(data) {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'CamperMaintenanceModal',
                data,
            });
        },
        displayConfirmContactDetailsModal() {
            return this.$store.dispatch(modalActions.MODAL_OPEN, {
                component: 'ConfirmContactDetailsModal',
                closeable: false,
                obtrusive: true,
                fullscreenMobile: false,
            });
        },
        async displayAddonsAnnouncementModal() {
            try {
                await this.$store.dispatch(modalActions.MODAL_OPEN, {
                    component: 'AddonsAnnouncementModal',
                    obtrusive: true,
                    fullscreenMobile: false,
                    style: { lesserPadding: true, bgColor: namedColors.blue },
                });
            } catch (err) {}
        },

        /**
         * This method opens up a list of obtrusive (i.e. unclosable) modals when needed
         *
         * These modals are used to gather information from the user on login (or page reload).
         * In case a user must fill in multiple modals, they're ordered here so they turn up one after the other.
         */
        async displayObtrusiveModalsInOrder() {
            const {
                user,
                isAdmin,
                isOwner,
                customTimestamps,
                myVehicles,
                isImpersonating,
                hasAnyVehicleWithOmocomInsurance,
            } = this.$store.getters;

            if (!user) {
                return;
            }

            const acceptTermsModalNeeded = user.mustAcceptNewTerms && !isAdmin && !isImpersonating;
            const optForOmocomInteriorInsuranceModalNeeded =
                user && user.askedToOptInForOmocomInteriorInsuranceAddon === false && hasAnyVehicleWithOmocomInsurance;
            const shouldAnnounceOwnerAddons =
                isOwner && myVehicles.length > 0 && !customTimestamps?.includes(ADDONS_ANNOUNCED_AT);

            // This is where we decide the order of appearance of the modals
            if (acceptTermsModalNeeded) {
                await this.displayAcceptUserTermsModal();
            }

            if (this._confirmContactDetailsModalNeeded()) {
                await this.displayConfirmContactDetailsModal();
            }

            if (optForOmocomInteriorInsuranceModalNeeded) {
                await this.displayOptForOmocomInteriorInsuranceModal();
            }

            if (this._confirmOmocomDetailsModalNeeded()) {
                await this.displayConfirmOmocomDetailsModal();
            }

            if (shouldAnnounceOwnerAddons) {
                await this.displayAddonsAnnouncementModal();
                this.$meService.setCustomTimestamp(ADDONS_ANNOUNCED_AT);
            }
        },

        _confirmOmocomDetailsModalNeeded() {
            const { isOwner, hasAnyActiveVehicle, myVehicles } = this.$store.getters;
            const vehicle = myVehicles?.[0];

            if (!isOwner || !hasAnyActiveVehicle) {
                return false;
            }

            const hasInsurance = vehicle.hasPlatformInsurance;
            const neverConfirmed = !vehicle.insuranceRequirementsConfirmedAt;
            const confirmedBeforeOmocom =
                !neverConfirmed && isBefore(parseISO(vehicle.insuranceRequirementsConfirmedAt), parseISO('2022-01-20'));

            return hasInsurance && (neverConfirmed || confirmedBeforeOmocom);
        },

        _confirmContactDetailsModalNeeded() {
            const { isOwner, user } = this.$store.getters;

            // If any of the asked for details are missing, we assume that the user hasn't had any bookings yet,
            // and thus we don't need to confirm their contact details (since we mainly to it for DAC7 KYC regulations).
            const anyDetailsMissing =
                !user.email || !user.address || !user.zipCode || !user.city || !user.country || !user.phone;

            if (!isOwner || !user || anyDetailsMissing) {
                return false;
            }

            if (!user.contactDetailsConfirmedAt) {
                return true;
            }

            const previousYearJan1 = startOfYear(subYears(new Date(), 1));
            const lastConfirmed = parseISO(user.contactDetailsConfirmedAt);

            return isBefore(lastConfirmed, previousYearJan1);
        },
    },
};
